<template>
  <div class="bbb message">
    <!-- <van-nav-bar title="我的问诊" @click="chat" /> -->

    <div class="page-wrapper">
      <div class="tabs-wrapper">
        <div
          v-for="item in [
            ['今日问诊', '问诊消息'],
            ['可续方问诊', '去续方'],
            ['可退款问诊', '可退款问诊'],
            // ['可退款问诊', '可退款问诊'],
            // ['消息', '系统消息'],
          ]"
          :key="item[1]"
          class="tab-item"
          :class="[active == item[1] && 'active']"
          @click="
            () => {
              active = item[1];
              activeChange();
            }
          "
        >
          {{ item[0] }}
        </div>
      </div>

      <div v-if="active == '问诊消息'">
        <div class="margin_top bpd" v-for="(item, index) of messageList">
          <van-steps :active="item.active" active-color="#38f">
            <van-step>申请</van-step>
            <van-step>支付</van-step>
            <van-step>问诊</van-step>
            <van-step>开方</van-step>
            <van-step>审方</van-step>
            <van-step>完成</van-step>
          </van-steps>

          <div class="title display jcsb aic">
            <p class="title_name">
              <span class="title_icon"></span>
              <span>{{ consultType[item.consultType - 1] }} </span>
              <b> （{{ item.pres_body.patient.name }}）</b>
            </p>
            <p>
              <van-button
                v-if="item.stateName == '待支付'"
                color="#1557FF"
                size="small"
                @click="YBPayImg(item)"
                class="message_btn"
                >请支付</van-button
              >
              <van-button
                v-else-if="item.stateName == '待申请'"
                color="#1557FF"
                size="small"
                @click="created_order(item)"
                class="message_btn"
                >申请问诊</van-button
              >
              <!-- && item.ih_type !=='NewYXHospital' -->
              <van-button
                v-else-if="item.stateName == '已完成'"
                color="#1557FF"
                size="small"
                @click="openPres(item)"
                class="message_btn"
                >下载</van-button
              >
              <van-button
                v-else-if="
                  item.ih_type == 'NewYXHospital' && item.pay_state == '3'
                "
                color="#1557FF"
                size="small"
                @click="chat(item)"
                class="message_btn"
                >请和医生沟通</van-button
              >
              <van-button
                v-else-if="
                  item.ih_type != 'NewYXHospital' &&
                  (item.pay_state == '1' ||
                    item.pay_state == '2' ||
                    item.pay_state == '3')
                "
                color="#1557FF"
                size="small"
                @click="chat(item)"
                class="message_btn"
                >请和医生沟通</van-button
              >
              <!-- <van-button color="#1557FF" size="small" @click="chat(item)" class="message_btn">请和医生沟通</van-button> -->
            </p>
          </div>
          <div class="margin_top padding_lr_4_1">
            <span class="Medication_title">历史用药：</span>
            <span
              class="Medication color_20d8de"
              v-for="(t, i) of item.DrugsList"
              >{{ t.name + '等' }}</span
            >
          </div>
          <div class="margin_top padding_lr_4_1 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">申请时间：</span>
              <span class="Medication Medication_time">{{
                item.created_at.slice(10)
              }}</span>
            </div>
            <span class="message_state">
              {{ item.stateName == '待支付' ? '' : item.stateName }}
            </span>
          </div>
          <div class="margin_top padding_lr_4_1 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">接诊医生：</span>
              <span class="Medication Medication_time">{{
                item.DoctorName
              }}</span>
            </div>
          </div>
          <div
            class="margin_top padding_lr_4_1 display jcsb padding_r_0"
            v-if="item.message"
          >
            <div style="width: 100%">
              <el-alert
                title="问诊耗时过长建议退款重新申请"
                type="warning"
                :closable="false"
                show-icon
              >
              </el-alert>
            </div>
          </div>
          <div
            class="margin_top padding_lr_4_1 display jcsb padding_r_0"
            v-if="messageType[item.stateName]"
          >
            <div style="width: 100%">
              <el-alert
                :title="messageType[item.stateName]"
                type="warning"
                :closable="false"
                show-icon
              >
              </el-alert>
            </div>
          </div>
          <div
            class="margin_top padding_lr_4_1 display jcsb padding_r_0"
            v-if="item.message"
          >
            <div></div>
            <!-- <span class="message_state">
              <van-button  @click="refund(item)" class="message_btn" color="#E6A23C" size="small">申请退款</van-button>
            </span> -->
          </div>
          <div
            class="margin_top padding_lr_4_1 display jcsb padding_r_0"
            v-if="item.ih_type == 'NewYXHospital' && item.reminderDoctor"
          >
            <div></div>
            <span class="message_state">
              <van-button
                @click="AskUrge(item)"
                color="linear-gradient(to right, #1557FF, #1557FF)"
                size="small"
                class="message_btn"
                >催医生沟通</van-button
              >
            </span>
          </div>
        </div>
        <van-nodata
          v-if="messageList.length == 0"
          :figure="imgUrl[0]"
          title="今日暂无问诊，历史问诊请进入我的页面进行查看"
        />
        <div v-if="messageList.length <= 1" class="qr-wrap">
          <img src="../assets/image/QRcode.jpg" alt="" />
          <p style="text-align: center; padding: 0 10px; font-weight: 600">
            请保存截屏后长按该图片关注公众号，以更快获得问诊进展
          </p>
        </div>
      </div>
      
      <div v-if="active == '可退款问诊'">
        <div class="margin_top bpd" v-for="(item, index) of messageList">
          <div class="title display jcsb aic">
            <p>
              <span class="title_icon"></span>
              <span>{{ consultType[item.consultType - 1] }}</span>
            </p>
            <p>
              <van-button
                color="#1557FF"
                size="small"
                @click="refund(item)"
                class="message_btn"
                >申请退款</van-button
              >
            </p>
          </div>
          <div class="margin_top padding_lr_4_1">
            <span class="Medication_title">历史用药：</span>
            <span
              class="Medication color_20d8de"
              v-for="(t, i) of item.DrugsList"
              >{{ t.name + '等' }}</span
            >
          </div>
          <div class="margin_top padding_lr_4_1 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">申请时间：</span>
              <span class="Medication Medication_time">{{
                item.created_at
              }}</span>
            </div>
            <span class="message_state">{{ item.stateName }} </span>
          </div>
        </div>

        <van-nodata
          v-if="messageList.length == 0"
          :figure="imgUrl[0]"
          title="仅显示最近7天可退款问诊"
        />
        <div v-if="messageList.length <= 1" class="qr-wrap">
          <img src="../assets/image/QRcode.jpg" alt="" />
          <p style="text-align: center; padding: 0 10px; font-weight: 600">
            请保存截屏后长按该图片关注公众号，以更快获得问诊进展
          </p>
        </div>
      </div>
      <div v-if="active == '系统消息'">
        <div class="margin_top bpd" v-for="(item, index) of News">
          <div class="title display jcsb aic">
            <p>
              <span class="title_icon"></span>
              <span>系统消息</span>
            </p>
            <p>
              <span class="message_state">{{ item.stateName }}</span>
            </p>
          </div>
          <div class="margin_top padding_lr_4_1">
            <span class="Medication_title">{{ item.txt }}</span>
          </div>
          <div class="margin_top padding_lr_4_1 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">时间：</span>
              <span class="Medication">{{ item.created_at.slice(10) }}</span>
            </div>
          </div>
        </div>
        <van-nodata
          v-if="News.length == 0"
          :figure="imgUrl[0]"
          title="暂无消息"
        />
      </div>
      <div v-if="active == '去续方'">
        <div class="margin_top bpd" v-for="(item, index) of messageList">
          <div class="title display jcsb aic">
            <p>
              <span class="title_icon"></span>
              <span>{{ consultType[item.consultType - 1] }}</span>
            </p>
            <p>
              <van-button
                color="#1557FF"
                size="small"
                @click="renew(item)"
                class="message_btn"
                >去续方</van-button
              >
            </p>
          </div>
          <div class="margin_top padding_lr_4_1">
            <span class="Medication_title">历史用药：</span>
            <span
              class="Medication color_20d8de"
              v-for="(t, i) of item.DrugsList"
              >{{ t.name + '等' }}</span
            >
          </div>
          <div class="margin_top padding_lr_4_1 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">申请时间：</span>
              <span class="Medication Medication_time">{{
                item.created_at
              }}</span>
            </div>
            <span class="message_state">{{ item.stateName }} </span>
          </div>
        </div>
      </div>
      <footer_ />
    </div>
  </div>
</template>
<script>
import footer_ from '../components/footer.vue';
import method from '../assets/js/util.js';
import mixin from '@/utils/mixin';
// import { TUIConversation,  } from "../TUIKit";
// import {  TUIStore,StoreName,conversationList} from "@tencentcloud/chat-uikit-engine";
export default {
  mixins: [mixin],
  components: {
    footer_,
    // TUIConversation,
  },
  data() {
    return {
      active: '问诊消息',
      messageList: [],
      messageType: {
        已支付待接诊: '请耐心等待医生接诊，约一分钟',
        已接诊: '请点击右上方按钮和医生沟通',
        咨询中: '请点击右上方按钮和医生沟通',
        取消订单: '请重新申请问诊',
        未支付已过期: '请重新申请问诊',
        订单咨询已过期: '请重新申请问诊',
        拒绝接单: '请重新申请问诊',
        问诊被结束: '请重新申请问诊',
        审核通过: '请去药店医保客户端使用此统筹处方',
        审核中: '请耐心等待审核即可，约一分钟',
        待审核: '请耐心等待审核即可，约一分钟',
        待问诊: '请耐心等待医生接诊，约一分钟',
        问诊中: '请点击右上方按钮和医生沟通',
        审核不通过: '等待医生重新提交处方即可',

        待支付: '请点击上方按钮进行支付',
        待申请: '请点击上方按钮申请问诊',
        已完成: '请去药店医保客户端使用此统筹处方',
        医生重新开方中: '医生正在重新开方中，请等待即可',
      },
      currentConversationID: '',
      wxchatImg: require('../assets/image/QRcode.jpg'),
      srcList: [
        require('../assets/image/QRcode.jpg'),
        require('../assets/image/QRcode.jpg'),
      ],
      consultType: ['图文咨询', '电话咨询', '视频咨询', '复方购药', '急速问诊'],
      News: [],
      times: null,
    };
  },
  methods: {
    //催医生沟通
    async AskUrge(item) {
      let userInfo = JSON.parse(localStorage.getItem('userinfo'));
      let res = await this.$api.article.InternetHospitalGet(
        { search_db: 'AskUrge', ih_type: localStorage.getItem('ih_type') },
        {
          id: item.id,
          pharmacy_id: item.pharmacy_id,
          user_id: userInfo.user_id,
        }
      );
      if (res.data.code == '200') {
        this.$message({
          message: '已催医生，请稍后',
          type: 'success',
        });
      }
    },
    //申请问诊
    created_order(item) {
      let data = {
        doctor_id: item.doctorId,
        pres_id: item.id,
      };
      this.$api.article.CreatedInternetPres(data).then((res) => {
        this.$message({
          message: '申请问诊成功',
          type: 'success',
        });
        this.loadList();
      });
    },
    async chat(item) {
      let imChatId = `C2C${item.doctorId}`;
      console.log(item,'____________')
      await this.IMSIG(item.ih_type, item.id);
      let that = this;
      if (item.ih_type == 'NewYXHospital') {
        imChatId = `GROUP${item.pres_id}`;
      }
      sessionStorage.setItem(
        'ChatPrseInfo',
        JSON.stringify({
          chatId: imChatId,
          pres_id: item.pres_id,
          patient_name: item.pres_body.patient.name,
          DoctorName:item.DoctorName
        })
      );
      if (item.if_first_chat == '0') {
        that.putstate('Pres', 'id', item.id, { if_first_chat: 1 });
        that.ChatMessage(
          item.ih_type == 'NewYXHospital' ? item.pres_id : item.doctorId,
          item.doctorId,
          item.pres_body,
          item,
          item.ih_type == 'NewYXHospital' ? 'GROUP' : 'C2C'
        );
        that.$router.push({
          name: 'chat',
          query: {
            chatId: imChatId,
            pres_url: item.pres_url,
            pres_id: item.pres_id,
            doctorId: item.doctorId,
            id: item.id,
            pharmacy_id: item.pharmacy_id,
            userName: item.pres_body.patient.name,
            doctorName: item.DoctorName,
          },
        });
      } else {
        that.$router.push({
          name: 'chat',
          query: {
            chatId: imChatId,
            pres_url: item.pres_url,
            pres_id: item.pres_id,
            doctorId: item.doctorId,
            id: item.id,
            pharmacy_id: item.pharmacy_id,
            userName: item.pres_body.patient.name,
            doctorName: item.DoctorName,
          },
        });
      }
    },
    async loadList(
      data = { query: { found_id: localStorage.getItem('userrole_id') } }
    ) {
      if (!localStorage.getItem('userrole_id')) {
        return;
      }
      let that = this;
      setTimeout(() => {
        that.loading.close();
      }, 5000);
      try {
        // 发起异步请求获取文章列表数据
        // start_time:this.date[0],end_time:this.date[1]
        // start_time:'2024-07-23 00:00:00',end_time:'2024-07-30 00:00:00'
        const articleRes = await this.$api.article.query(
          {
            search_db: 'Pres',
            query_type: 'equal',
            start_time: this.date[0],
            end_time: this.date[1],
          },
          data
        );
        const articleData = articleRes.data.result;
        if (articleData.length !== 0 && articleData[0].doctorId == 'None') {
          this.$api.article.get_doctor(articleData[0].id);
        }
        articleData.forEach((element, index) => {
          this.$api.article
            .query(
              { search_db: 'Doctor', query_type: 'first' },
              {
                query: {
                  doctor_id: element.doctorId,
                },
              }
            )
            .then((res) => {
              this.$set(
                this.messageList[index],
                'DoctorName',
                res.data.result.name
              );
            });
        });
        // 使用 Promise.all() 等待所有的异步请求完成
        await Promise.all(
          articleData.map(async (element) => {
            element.pres_body = JSON.parse(element.pres_body);
            element['active'] = this.getactive(element);
            element['message'] = this.isTimeDifferenceGreater(element);
            element['DrugsList'] = [element.pres_body.Drugs[0]];
            let pay_state = [
              '待支付',
              '已支付待接诊',
              '已接诊',
              '咨询中',
              '已完成',
              '取消订单',
              '未支付已过期',
              '订单咨询已过期',
              '',
              '拒绝接单',
              '退款成功',
            ];
            element['stateName'] = '';
            if (element.doctorId == 'None') {
              element.stateName = '待接单';
              return;
            }
            if (element.pres_id == 'None') {
              element.stateName = '待申请';
              return;
            }
            if (element.pay_state == '0') {
              element.stateName = pay_state[element.pay_state * 1];
              return;
            }
            if (element.state == 'adopt') {
              element.stateName = '已完成';
              return;
            }

            if (
              (element.pay_state * 1 > 4 && element.pay_state * 1 <= 10) ||
              element.pay_state == '2' ||
              element.pay_state == '1'
            ) {
              element.stateName = pay_state[element.pay_state * 1];
            } else {
              if (element.pay_state == '4') {
                if (element.pharmacistStatus == '2') {
                  element.stateName = '已完成';
                } else {
                  element.stateName = '问诊被结束';
                }
              } else if (element.pay_state == '3') {
                if (element.pharmacistStatus == '3') {
                  element.stateName = '医生重新开方中';
                } else if (element.pharmacistStatus == '2') {
                  element.stateName = '审核通过';
                } else if (element.pharmacistStatus == '1') {
                  element.stateName = '审核中';
                } else {
                  let doctorCheckStatusAll = [
                    '待问诊',
                    '问诊中',
                    '已完成',
                    '审核不通过',
                  ];
                  element.stateName =
                    doctorCheckStatusAll[element.doctorCheckStatus];
                }
              }
            }
            element['reminderDoctor'] = this.isreminderDoctor(element);
          })
        );

        // 所有的异步请求都返回后，设置 messageList
        this.messageList = articleData;

        this.loading.close();
      } catch (error) {
        console.error('Error occurred:', error);
      }
    },
    getactive(item) {
      let start = 0;
      if (item.pres_id !== 'None') {
        start += 1;
      }
      if (item.pay_state > 0) {
        start += 1;
      }
      if (item.doctorCheckStatus * 1 < 3) {
        start += item.doctorCheckStatus * 1;
      }
      if (item.pharmacistStatus > 0) {
        start += 1;
      }
      if (item.state == 'adopt') {
        start = 6;
      }
      return start;
    },
    isTimeDifferenceGreater(item) {
      var d = new Date().getTime();
      if (
        method.getFutureDate(method.formatDate(d), item.created_at, 35) &&
        item.doctorCheckStatus < 1 &&
        item.pay_state > 0 &&
        item.pay_state < 5
      ) {
        return true;
      }
      return false;
    },
    //判断是否可以催单
    isreminderDoctor(item) {
      var d = new Date().getTime();
      return (
        method.getFutureDate(method.formatDate(d), item.created_at, 5) &&
        item.doctorId !== 'None' &&
        item.state !== 'adopt' &&
        item.pay_state != 10 &&
        item.stateName !== '取消订单'
      );
    },
    //刷新支付状态
    Refresh_pay_state(item) {
      this.$api.article.syncPres(item.pres_id).then((res) => {
        if (res.data.result.pay_state !== '0') {
          this.activeChange();
        }
      });
    },
    activeChange() {
      if (this.active == '可退款问诊') {
        this.date[0] =
          method.formatDate(
            method.getFutureDate(this.date[0], 7, '-'),
            'yyyy-MM-dd'
          ) + ' 00:00:01';
        this.loadList({
          query: {
            found_id: localStorage.getItem('userrole_id'),
            pay_state: '1',
          },
        });
      } else if (this.active == '问诊消息') {
        var d = new Date().getTime();
        this.date = [
          method.formatDate(d).slice(0, 10) + ' 00:00:01',
          method.formatDate(d).slice(0, 10) + ' 23:59:59',
        ];
        this.loadList({
          query: { found_id: localStorage.getItem('userrole_id') },
        });
      }else if(this.active == '去续方'){

        var d = new Date().getTime();
        this.date = [
          method.formatDate(method.getDateTimeBeforeSeconds(86400*30*3)).slice(0, 10) + ' 00:00:01',
          method.formatDate(d).slice(0, 10) + ' 23:59:59',
        ];
        console.log(this.date)
        this.loadList({
          query: { found_id: localStorage.getItem('userrole_id'),state:'adopt' },
        });
      }
    },
    openPres(item) {
      console.log(item);
      if (item.ih_type == 'LodYXHospital') {
        window.location.href = item.presDownUrl;
      } else {
        // InternetHospitalGet
        let params = {
          search_db: 'Pres',
        };
        let data = {
          id: item.id,
          pharmacy_id: item.pharmacy_id,
        };
        this.$api.article.InternetHospitalGet(params, data).then((res) => {
          console.log(res);
          if (res.data.result.data) {
            res.data.result.data.presInfo = JSON.parse(
              res.data.result.data.presInfo
            );
            let item_url = res.data.result.data.presInfo.find((item) => {
              return item.url;
            });
            if (item_url) {
              window.location.href = item_url.url;
            }
          }
        });
      }
    },
    async imLogin(id, Sig) {
      const res = await this.$tim.Utils.imLogin(id, Sig);
    },
    // 跳转支付连接
    YBPayImg(item) {
      let pres_id = item.pres_id;
      let that = this;
      this.$api.article
        .YBPayImg(pres_id, 1, localStorage.getItem('ih_type'))
        .then((res) => {
          if (res) {
            this.$alert('请支付成功后重新进入问诊', '提示', {
              confirmButtonText: '确定',
              callback: (action) => {
                sessionStorage.removeItem('Drugs');
                sessionStorage.removeItem('Current');
                sessionStorage.removeItem('disease');
                sessionStorage.removeItem('patientId');
                if (res.data.result.data.slice(0, 4) == 'http') {
                  window.location.href = res.data.result.data;
                } else {
                  that.$router.push('/message');
                }
              },
            });
          }
        });
    },
    refund(item) {
      let pres_id = item.pres_id;
      let that = this;
      this.$api.article.YBPayImg(pres_id, 2, item.ih_type).then((res) => {
        this.$alert('请确认是否退款', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {
            sessionStorage.removeItem('Drugs');
            sessionStorage.removeItem('Current');
            sessionStorage.removeItem('disease');
            sessionStorage.removeItem('patientId');
            if (res.data.result.data.slice(0, 4) == 'http') {
              window.location.href = res.data.result.data;
            } else {
              that.$router.push('/message');
            }
          },
        });
      });
    },


    //去续方
    renew(item){
      sessionStorage.setItem('renew_pres_id',item.id)
      this.$router.push({
        path: '/pres',
        query: {
          consultType: item.consultType*1,
        }
      });
    }
  },
  created() {
    
    
    var d = new Date().getTime();
    this.date = [
      method.formatDate(d).slice(0, 10) + ' 00:00:01',
      method.formatDate(d).slice(0, 10) + ' 23:59:59',
    ];
    console.log(   );
    // 发起异步请求获取新闻列表数据
    this.$api.article
      .query(
        {
          search_db: 'News',
          query_type: 'equal',
          start_time: this.date[0],
          end_time: this.date[1],
        },
        { query: { userrole_id: localStorage.getItem('userrole_id') } }
      )
      .then((res) => {
        this.News = res.data.result;
        let that = this;
        this.loading = this.$loading({
          lock: true, //lock的修改符--默认是false
          text: '加载中。。。', //显示在加载图标下方的加载文案
          color: 'rgba(255, 255, 255, 1)',
          spinner: 'el-icon-loading', //自定义加载图标类名
          background: 'rgba(0, 0, 0, 0.9)', //遮罩层颜色
          target: document.querySelector('#table'), //loadin覆盖的dom元素节点
        });

        setTimeout(() => {
          // 在IMSIG函数执行完后执行的操作
          // 在这里添加其他操作
          // that.IMSIG()
          if (
            localStorage.getItem('userinfo') &&
            localStorage.getItem('userrole_id')
          ) {
            clearInterval(that.times);
            that.activeChange();
            that.times = setInterval(function () {
              if (that.$route.name == 'message') {
                that.activeChange();
              }
            }, 10000);
          }
        }, 1000);
      });
      if(this.$route.query.type){
        this.active = this.$route.query.type
      }
  },
  mounted() {},
  destroyed() {
    clearInterval(this.times);
  },
};
</script>
<style lang="less" scoped>
.van-nav-bar__title {
  color: #111 !important;
  font-weight: 600 !important;
}

.bbb {
  min-height: 100vh;
  background: linear-gradient(to bottom, #eef4fd, #f6f6f6);
}
.message{
  padding-top: 0.9rem;
}
.page-wrapper {
  padding: 0 0.6rem 4rem;

  .tabs-wrapper {
    // margin-top: 1rem; 
    position: relative;
    height: 3.06rem;
    background: #ffffff;
    border-radius: 0.69rem;

    display: flex;
    justify-content: space-between;

    .tab-item {
      position: relative;
      padding: 1rem 1.34rem 0;

      font-family: SourceHanSansCN;
      font-weight: 400;
      font-size: 0.94rem;
      color: #848484;

      &.active {
        font-family: SourceHanSansCN;
        font-weight: bold;
        font-size: 0.94rem;
        color: #282828;

        &::before {
          content: '';
          position: absolute;

          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 1.78rem;
          height: 0.25rem;
          background: #1557ff;
          border-radius: 0.13rem;
        }
      }
    }
  }
}

::v-deep .van-step--process .van-step__active-circle {
  border-color: #1557ff;

  &::after {
    background-color: #1557ff;
    transform: scale(0.6);
  }
}

.title_name {
  font-family: SourceHanSansCN;
  font-weight: bold;
  font-size: 0.94rem;
  color: #282828;

  b {
    color: #1557ff;
  }
}

.title_icon {
  margin-right: 0.41rem;
  display: inline-block;

  width: 0.19rem;
  height: 0.81rem;
  background: #1557ff;
  border-radius: 0.09rem;
}

.Medication_title {
  font-family: SourceHanSansCN;
  font-weight: 500;
  font-size: 0.88rem;
  color: #282828;
}

.Medication {
  font-family: AlibabaPuHuiTi_2_55_Regular;
  font-weight: 400;
  font-size: 0.94rem;
  color: #d6d6d6;
  line-height: 1.31rem;
}

.qr-wrap {
  margin-top: 4.5rem;
  display: flex;
  flex-direction: column;

  align-items: center;
  position: relative;

  img {
    width: 7.56rem;
    height: 7.56rem;
  }

  p {
    margin-top: 1.69rem;
    width: 15.31rem;
    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 0.94rem;
    color: #9a9a9a;
    line-height: 1.5rem;
  }
}

.bpd {
  padding: 0.97rem 0.91rem 1.19rem;
  background: #ffffff;
  border-radius: 0.69rem;
}

.van-nodata {
  top: 4vh;
  transform: none;
}

.padding_lr_4_1 {
  padding-left: 0.41rem;
  padding-right: 0.41rem;
}
</style>
